//import "admin-lte"
import axios from "axios"
import hljs from 'https://unpkg.com/@highlightjs/cdn-assets@11.9.0/es/core.min.js'
import json from 'https://unpkg.com/@highlightjs/cdn-assets@11.9.0/es/languages/json.min.js';
import Swal from 'sweetalert2'
//import "admin-lte"
import "bootstrap"
import "jquery"

import $ from 'jquery';

hljs.registerLanguage('json', json);
export const script_editor_app={
    mounted(){
        this.getscriptdata()
    },
    data() {
        return {
            authenticity_token:window.authenticity_token,
            script_id:window.script_id,
            original_script_data:{},
            placeholders:{new_command:null,new_trigger:null, new_role:null,new_condition:{val:null,jump:null}},
            settings:{actor_types:[],command_names:[],step_types:[]},
            script_data:{
                name:"",
                description:"",
                glady_start:false,
                triggers:[],
                roles:[],
                script_steps:[{condition:{}}]
            }
        }
    },
    methods: {
        getscriptdata:function(){
            axios.get("/scripts/"+this.script_id+".json").then(res=>{
                this.original_script_data=res.data
                this.script_data=res.data.script_data
                this.settings=res.data.settings
            }).catch(err=>{
                alert("error get_script",err)
            })
        },
        update_script:function(){
            //this.script_data.script_steps_attributes=this.script_data.script_steps
            //delete this.script_data.script_steps
            axios.patch("/scripts/"+this.script_id+".json",{authenticity_token:this.authenticity_token,script:this.script_data}).then(function(res){
                console.log("RESULTADO UPDATE",res);
                $(document).Toasts('create', {
                    title: 'Script Saved',
                    autohide:true,
                    delay:1200,
                    icon:"fas fa-exclamation-triangle",
                    class:"bg-success mt-4 mr-4"
                  })
            }).catch(err=>{
                
            })
        },

        masa:function(code){
            const el = document.createElement("code");
            el.innerHTML=JSON.stringify(code, null, 2)   
            el.setAttribute("class", "language-json")
            hljs.highlightElement(el)
            return el.outerHTML

        },
        step_color:function(step_type){
            switch (step_type){
                case "command":
                    return "lightblue";
                    break;
                case "condition":
                    return "orange";
                    break;
                case "thread_break":
                    return "pink"
                    break;
                default:
                    return "gray"
            }

        },
        new_step:function(step_type,command=null){
            let step_number=this.script_data.script_steps.length
            if (step_type=='command'){
                this.script_data.script_steps.push({
                    step_number:step_number,
                    type:step_type,
                    command:{name:command,parameters:{}}
                })
                $("#newcommand").modal("hide")
            } else if(step_type=='condition') {
                this.script_data.script_steps.push({
                    step_number:step_number,
                    type:step_type,
                    condition:{op:"is",var:"",options:[],else:0}
                })
            } else if(step_type=='thread_break'){
                this.script_data.script_steps.push({
                    step_number:step_number,
                    type:step_type,
                    thread_break:{reason_end:"user_end",forget_actor:false}
                })
            }

        },
        change_order:function(index,down){
            let newindex = down ? index + 1 : index - 1;
            if (newindex<0 || newindex>=this.script_data.script_steps.length) {
                return;
            }
            const temp=this.script_data.script_steps[index]
            this.script_data.script_steps[index]=this.script_data.script_steps[newindex]
            this.script_data.script_steps[newindex]=temp;
            this.script_data.script_steps.forEach((step,index)=>{
                step.step_number=index
            })
        },
        delete_step:function(index){
            Swal.fire({
                title: "Are you sure delete this step?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              }).then((result) => {
                if (result.isConfirmed) {
                    this.script_data.script_steps.splice(index,1)
                }
              });
            //
        },
        openmodal:function(modal_id){
            $("#"+modal_id).modal("show")
        },
        closemodal:function(modal_id){
            $("#"+modal_id).modal("hide")
        }
    }
    /*compilerOptions:{
        isCustomElement:function(tag){
            return tag.includes('json')
        }
    }*/
}
